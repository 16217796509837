"use client";

import { useEffect, useState } from "react";
import { usePathname, useRouter } from "next/navigation";
import NextLink from "next/link";
import Image from "next/image";
import makeBlockie from "ethereum-blockies-base64";
import {
  Badge,
  Button,
  Dropdown,
  Link,
  Navbar,
  SpheronApp,
  TextInput,
  ThemeMode,
} from "@spheron/ui-library";
import Console from '@spheron/ui-library/dist/assets/compute/console.svg';
import FizzNode from '@spheron/ui-library/dist/assets/compute/multi-service.svg';
import CloudApp from '@spheron/ui-library/dist/assets/compute/cloud-app.svg';
import ProviderEarning from '@spheron/ui-library/dist/assets/compute/provider-earning.svg';
import SpheronMenu from '@spheron/ui-library/dist/assets/spheron-menu.svg';
import SpheronIcon from '@spheron/ui-library/dist/assets/spheron-specific/spheron-logo.svg';
import SpheronLogoBlack from "@spheron/ui-library/dist/assets/spheron-specific/spheron-logo-dark.svg";
import SpheronLogoWhite from "@spheron/ui-library/dist/assets/spheron-specific/spheron-logo-light.svg";
import Wallet from "@spheron/ui-library/dist/assets/wallet-2.svg";
import UserIcon from "@spheron/ui-library/dist/assets/user.svg";
import UsersIcon from "@spheron/ui-library/dist/assets/users.svg";
import BlogsIcon from "@spheron/ui-library/dist/assets/document-2.svg";
import PenIcon from "@spheron/ui-library/dist/assets/pen.svg";
import Disconnect from "@spheron/ui-library/dist/assets/logout.svg";
import SearchIcon from "@spheron/ui-library/dist/assets/search.svg";
import { SAMPLE_PROVIDER_ADDRESS } from "@/config";
import { truncateAddress } from "@/utils";
import {
  connectMetaMaskAndGetAddress,
  disconnectMetaMask,
} from "@/services/wallet";
import { useTheme } from "@/utils/ThemeContext";

const NavbarComponent = () => {
  const router = useRouter();
  const pathname = usePathname();
  const [account, setAccount] = useState<string | null>(null);
  const [logoIcon, setLogoIcon] = useState<JSX.Element>(<></>);
  const [address, setAddress] = useState<string>("");
  const { theme } = useTheme();

  useEffect(() => {
    if (typeof localStorage !== "undefined") {
      setAccount(localStorage.getItem("myProviderAddress") || "");
    }
  }, []);

  useEffect(() => {
    if (theme) {
      if (theme === ThemeMode.LIGHT) {
        setLogoIcon(
          <button onClick={() => router.push("/")}>
            <SpheronLogoBlack className="w-[116px] cursor-pointer" />
          </button>
        );
      } else {
        setLogoIcon(
          <button onClick={() => router.push("/")}>
            <SpheronLogoWhite className="w-[116px] cursor-pointer" />
          </button>
        );
      }
    }
  }, [theme, router]);

  useEffect(() => {
    const handleAccountsChanged = (accounts: string[]) => {
      if (accounts.length > 0) {
        setAccount(accounts[0]);
      } else {
        setAccount(null);
        localStorage.removeItem("myProviderAddress");
      }
    };

    window?.ethereum?.on("accountsChanged", handleAccountsChanged);

    return () => {
      window?.ethereum?.removeListener(
        "accountsChanged",
        handleAccountsChanged
      );
    };
  }, []);

  const navItems = [
    {
      id: "docs",
      text: "Docs",
      handleClick: () => {
        window.open("https://docs.spheron.network/providers");
      },
    },
    // {
    //   id: 'community',
    //   text: 'Community',
    //   handleClick: () => {
    //     window.open('https://community.spheron.network/');
    //   },
    // },
    ...(account
      ? [
          {
            id: "my-provider",
            text: "My Provider",
            handleClick: async () => {
              const wallet = await connectMetaMaskAndGetAddress();
              if (wallet) {
                setAccount(wallet.address);
                window.open(`/${wallet.address}`, "_self");
              }
            },
          },
        ]
      : []),
  ];

  const dropdownItems = [
    {
      id: 1,
      label: 'CONSOLE',
      handleClick: () => window.open('https://console.spheron.network'),
      icon: <Console className="w-8 h-8" />,
    },
    {
      id: 2,
      label: 'FIZZ NODE',
      handleClick: () => window.open('https://fizz.spheron.network'),
      icon: <FizzNode className="w-8 h-8" />,
    },
    // {
    //   id: 3,
    //   label: (
    //     <div className="flex flex-row gap-x-2 items-center">
    //       <span>CLOUD</span>
    //       <span>
    //         <Badge
    //           text="Unavailable"
    //           size="small"
    //           solid={false}
    //           badgeType="default"
    //         />
    //       </span>
    //     </div>
    //   ),
    //   handleClick: () => window.open("https://app.spheron.network"),
    //   icon: <CloudApp className="w-8 h-8" />,
    //   disabled: true,
    // },
  ];

  return (
    <Navbar
      // logoIcon={logoIcon}
      logoIcon={
        <div className="flex items-center justify-start gap-x-2">
          <Dropdown
            dropdownType="button"
            className="w-fit"
            dropdownSize="default"
            bordersNone
            dropdownClassname="w-fit !ml-1"
            buttonImage={
              <button
                className="p-0.5 rounded
                transition hover:bg-action-ghost-press dark:hover:bg-dark-action-ghost-press"
              >
                <SpheronMenu className="w-8 h-8 text-logo-primary dark:text-dark-logo-primary" />
              </button>
            }
            options={dropdownItems.map((app) => {
              return {
                id: app.id,
                optionType: 'primary',
                dropdownRowClassname: 'hover:bg-transparent dark:hover:bg-transparent',
                label: (
                  <div className="w-80">
                    <SpheronApp
                      heading={
                        <div className="flex items-center justify-start gap-x-2">
                          <SpheronIcon className="w-6 h-6 text-logo-primary dark:text-dark-logo-primary" />
                          <span className="font-semibold">
                            {app.label}
                          </span>
                        </div>
                      }
                      helpText=""
                      icon={app.icon}
                    />
                  </div>
                ),
                handleClick: () => app.handleClick(),
                // disable: app.disabled,
              };
            })}
          />
          <NextLink href="/">
            <div className="flex items-center justify-start gap-x-1">
              <SpheronIcon className="w-10 h-10 text-logo-primary dark:text-dark-logo-primary" />
              <span className="text-base font-semibold">
                PROVIDER
              </span>
            </div>
          </NextLink>
        </div>
      }
      infoElements={
        <div className="hidden lg:flex flex-row gap-x-5">
          <>
            {navItems.map((item) => {
              return (
                <Link
                  key={item.id}
                  onClick={item.handleClick}
                  size="default"
                  text={item.text}
                  type="secondary"
                  isBold={false}
                />
              );
            })}
          </>
          {pathname !== "/" && (
            <>
              <div className="w-[256px] flex flex-row items-center">
                <TextInput
                  inputSize="compact"
                  leftIcon={
                    <SearchIcon
                      className="w-6 h-6 text-base-para-text-color
          dark:text-dark-base-para-text-color"
                    />
                  }
                  placeholder="Enter Provider Address"
                  value={address}
                  onChange={(value) => {
                    setAddress(value as string);
                  }}
                  onKeyDown={(e: any) => {
                    if (e.key === "Enter") {
                      router.push(`/${address}`);
                      setAddress("");
                    }
                  }}
                />
              </div>
            </>
          )}
          {account ? (
            <Dropdown
              dropdownClassname="w-[200px] items-center bg-base-bg"
              dropdownType="button"
              options={[
                // {
                //   optionType: 'primary',
                //   id: 0,
                //   label: (
                //     <div className="flex flex-row items-center justify-center gap-x-2">
                //       <UserIcon className="w-6 h-6" />
                //       <span>My Provider</span>
                //     </div>
                //   ),
                //   value: 'My Provider',
                //   handleClick: () => {
                //     window.open(`/${account}`, '_self');
                //   },
                //   // borderBottom: true,
                // },
                // {
                //   optionType: "primary",
                //   id: 0,
                //   label: (
                //     <div className="flex flex-row items-center justify-center gap-x-2">
                //       <UsersIcon className="w-6 h-6" />
                //       <span>Community</span>
                //     </div>
                //   ),
                //   value: "Community",
                //   handleClick: () => {
                //     window.open("https://community.spheron.network/");
                //   },
                //   // borderBottom: true,
                // },
                {
                  optionType: "primary",
                  id: 1,
                  label: (
                    <div className="flex flex-row items-center justify-center gap-x-2">
                      <BlogsIcon className="w-6 h-6" />
                      <span>Blogs</span>
                    </div>
                  ),
                  value: "Blogs",
                  handleClick: () => {
                    window.open("https://blog.spheron.network/");
                  },
                  // borderBottom: true,
                },
                {
                  optionType: "primary",
                  id: 2,
                  label: (
                    <div className="flex flex-row items-center justify-center gap-x-2">
                      <PenIcon className="w-6 h-6" />
                      <span>Report Bugs</span>
                    </div>
                  ),
                  value: "Report Bugs",
                  handleClick: () => {
                    window.open("https://blog.spheron.network/");
                  },
                  // borderBottom: true,
                },
                {
                  optionType: "primary",
                  id: 3,
                  label: (
                    <div className="flex flex-row items-center justify-center gap-x-2">
                      <Disconnect className="text-action-error-default w-6 h-6" />
                      <span className="text-action-error-default">
                        Disconnect
                      </span>
                    </div>
                  ),
                  value: "Disconnect",
                  handleClick: () => {
                    disconnectMetaMask();
                    setAccount("");
                  },
                },
              ]}
              buttonImage={
                <div
                  title={account}
                  className="flex flex-row gap-x-2 items-center"
                >
                  <Image
                    width={36}
                    height={36}
                    src={makeBlockie(account)}
                    alt=""
                    priority
                    className="rounded-full"
                  />
                  <span className="text-base-heading-text-color dark:text-dark-base-heading-text-color">
                    {truncateAddress(account)}
                  </span>
                </div>
              }
              dropdownSize="compact"
              bordersNone
            />
          ) : (
            <div>
              <Button
                classname="self-start max-h-[40px] min-w-[180px]"
                buttonType="primary"
                size="medium"
                label="Connect Wallet"
                fillWidth
                leftIcon={<Wallet className="w-5 h-5" />}
                onClick={async () => {
                  const wallet = await connectMetaMaskAndGetAddress();
                  if (wallet) {
                    setAccount(wallet.address);
                  }
                }}
              />
            </div>
          )}
        </div>
      }
    />
  );
};

export default NavbarComponent;
